import type {
	ActionPage,
	Describe,
	FieldDescription,
	FieldType,
} from '@cocoonspace/types/admin/describe'
import { useMemo } from 'react'
import { DESCRIBES } from '~/config/describes'
import type { EntityName } from '~/config/entities'
import { getAttributeColSize } from '~/pages/[describeId]/_utils/get-attribute-col-size'

export type UiFieldDescription = Omit<
	FieldDescription,
	'editable' | 'items'
> & {
	editable: boolean
	items?: {
		type?: FieldType
		fields: UiFieldDescription[]
	}
	linkType?: string
	asyncConfig?: {
		params: any
	}
}

export type UiDescribe = Omit<Describe, 'attributes' | 'filters'> & {
	filters: UiFieldDescription[]
	attributes: UiFieldDescription[]
}

export const convertEditable = (
	attributes: FieldDescription[],
	actionFilter?: ActionPage,
) =>
	attributes.map(({ items, ...attr }) => {
		const newAttr: UiFieldDescription = {
			...attr,
			editable: actionFilter ? attr.editable?.includes(actionFilter) : true,
		}

		if (items) {
			newAttr.items = {
				...items,
				...(items.fields && {
					fields: convertEditable(items.fields, actionFilter),
				}),
			}
		}

		return newAttr
	})

/**
 * Retrieve Entity describe infos
 * @param describeId
 */
export const useDescribe = (
	describeId: EntityName,
	actionFilter?: ActionPage,
) => {
	const describe = useMemo(() => {
		const describeData = DESCRIBES[describeId] as unknown as
			| Describe
			| undefined

		if (!describeData) return undefined

		const attributes =
			describeData.attributes
				.filter((attr) =>
					actionFilter ? !attr.hidden?.includes(actionFilter) : true,
				)
				.map((attr) => {
					if (attr.items?.fields) {
						attr.items.fields = attr.items.fields.filter((attr) =>
							actionFilter ? !attr.hidden?.includes(actionFilter) : true,
						)
					}

					return attr
				}) || []

		return {
			...describeData,
			filters: describeData.filters?.length
				? convertEditable(describeData.filters)
				: [],
			attributes: convertEditable(attributes, actionFilter).map((attr) => ({
				...attr,
				cols: getAttributeColSize(attr),
			})),
		}
	}, [describeId, actionFilter])

	return describe
}
