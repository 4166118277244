import type { EntityName } from '../entities'
import accounts from './accounts.json'
import booking_changes from './booking_changes.json'
import codes from './codes.json'
import codes_godfather from './codes_godfather.json'
import cohorts from './cohorts.json'
import denylist from './denylist.json'
import event_complaints from './event_complaints.json'
import event_logs from './event_logs.json'
import events from './events.json'
import extras from './extras.json'
import invoices from './invoices.json'
import orders from './orders.json'
import overstays from './overstays.json'
import params from './params.json'
import providers from './providers.json'
import quotations from './quotations.json'
import rules from './rules.json'
import spaces from './spaces.json'
import users from './users.json'
import venues from './venues.json'

export const DESCRIBES = {
	accounts,
	booking_changes,
	cohorts,
	codes,
	codes_godfather,
	denylist,
	events,
	event_complaints,
	event_logs,
	extras,
	invoices,
	orders,
	overstays,
	params,
	providers,
	quotations,
	rules,
	spaces,
	users,
	venues,
} satisfies Record<EntityName, unknown>
